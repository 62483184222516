@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=cyrillic');

html,body{
    margin: 0;
    font: normal normal 400 12px/ 1.2 "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
}
div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
@font-face {
    font-family: 'icon-3m';
    src:  url('assets/fonts/icon-3m.eot?58gghw');
    src:  url('assets/fonts/icon-3m.eot?58gghw#iefix') format('embedded-opentype'),
    url('assets/fonts/icon-3m.ttf?58gghw') format('truetype'),
    url('assets/fonts/icon-3m.woff?58gghw') format('woff'),
    url('assets/fonts/icon-3m.svg?58gghw#icon-3m') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-3m' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-circle-left:before {
    content: "\e910";
}
.icon-fb:before {
    content: "\e90b";
}
.icon-in:before {
    content: "\e90c";
}
.icon-tw:before {
    content: "\e90e";
}
.icon-map:before {
    content: "\e900";
}
.icon-local:before {
    content: "\e90d";
}
.icon-bd:before {
    content: "\e908";
}
.icon-clock:before {
    content: "\e909";
}
.icon-doc:before {
    content: "\e90a";
}
.icon-select:before {
    content: "\e901";
}
.icon-check:before {
    content: "\e902";
}
.icon-arrow:before {
    content: "\e903";
}
.icon-search:before {
    content: "\e904";
}
.icon-youtube:before {
    content: "\e90f";
}
.icon-close-outline:before {
    content: "\e905";
}
.icon-add-solid:before {
    content: "\e906";
}
.icon-minus-solid:before {
    content: "\e907";
}
.wrap{
    margin: 0 auto;
    max-width: 1098px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    min-width: 320px;
    overflow-y: hidden;
}
header{
    display: block;
    position: relative;
    height: 55px;
}
.logo {
    background-color: #FFF;
    background-image: url("assets/images/logo.jpeg");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 35px;
    width: 85px;
    padding: 0px;
    position: absolute;
    margin-left: 20px;
    left: 35px;
    top: 0;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    line-height: 85px;
    height: 85px;
    display: inline-block;
    vertical-align: middle;
}
.banner{
    height: 74px;
    transition: padding 220ms ease-in-out;
    padding: 24px 39px 24px 40px;
    background-position: center center;
    background-image: url("assets/images/banner.jpeg");
    background-size: cover;
}
.banner h1{
    padding: 4px 0;
    line-height: 1;
    display: none;
}
.btn{
    display: inline-block;
    padding: 10px 32px 10px;
    min-width: 112px;
    cursor: pointer;
    height: 40px;
    margin: 0 0 0 15px;
    margin-right: -2px;
    color: #333;
    background-color: #eef1f2;
    box-shadow: 0 0 0 1px #cad4d8;
    border-radius: 100px;
    line-height: 13px;
    font-size: 13px;
    text-align: center;
    overflow: hidden;
    font-weight: 500;
    transition: all 120ms ease-in;
    outline: none;
    border: none;
}
.btn:hover,.btn:focus{
    background-color: #cad4d8;
    box-shadow: 0 0 0 1px #9da9af;
    top: 0;
    bottom: 0;
    transition: 120ms ease-in;
}
.btn-primary{
    padding: 10px 32px 10px;
    min-width: 112px;
    max-width: 100%;
    height: 40px;
    background-color: #ffa126;
    color: white;
}
.btn-primary:hover,.btn-primary:focus{
    box-shadow: none;
    background-color: #f28900;
}
.btn:focus{
    box-shadow: 0 0 0 2px #005dba;
}
.btn-group{
    padding-top: 9px;
    text-align: right;
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    left: auto;
    padding-left: 61.5px;
    white-space: nowrap;
}
.btn-select .icon-select{
    color: #87969e;
    margin: 0 7px 0 14px;
}
.btn-dropdown{
    display: inline-block;
}
.btn-dropdown.show .container-select{
    display: block;
}
.container-select{
    display: none;
    z-index: 50;
    top: 60px;
    right: 0;
    bottom: auto;
    left: auto;
    min-width: 150px;
    position: absolute;
    padding: 7px 0 7px 0;
    border: 1px solid rgba(213, 217, 222, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    overflow: visible;
}
.container-select:before {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    content: " ";
    font-size: 0;
    top: -20px;
    right: 18px;
    border: 10px solid rgba(255, 255, 255, 1);
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
}
.list-select{
    list-style: none;
    padding: 0;
    margin: 0 -1px;
    text-align: left;
}
.list-select li{
    padding: 4px 10px;
    color: #4a4a4a;
    cursor: pointer;
}
.list-select li:hover{
    background-color: #e6ebed;
}
.list-select .active,.list-select .active:hover{
    background-color: #005dba;
    color: white;
}
.container{
    padding: 18px 40px 18px 40px;
}
h2 {
    display: block;
    font-size: 20px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #333;
    border-bottom: none;
    line-height: 24px;
    padding-bottom: 4px;
    margin-top: 3px;
}
.input-group{
    height: 43px;
    position: relative;
    margin: 24px 3px 18px;
}
.input-group input[type="text"]{
    padding-left: 27px;
    color: #666;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.input-group .icon-search{
    font-size: 12px;
    text-indent: 0;
    line-height: 1.34;
    position: absolute;
    top: 14px;
    left: 10px;
    z-index: 1;
    cursor: pointer;
    color: #8b9399;
    width: 16px;
    height: 16px;
    opacity: 0.9;
}
.input-group .btn{
    width: 133px;
    height: 40px;
    margin-right: 0;
    padding-top: 9px;
    position: absolute;
    right: 1px;
    top: 1px;
}
input[type="text"]{
    box-sizing: border-box;
    padding: 0 4px 0 5px;
    width: 100%;
    height: 42px;
    border-radius: 100px;
    box-shadow: 0 0 0 1px #cad4d8;
    border-color: transparent;
    outline: none;
}
.row{
    padding-top: 35px;
    display: table;
}
.row:before,.row:after{
    content: '';
    display: table;
}
.row:after{
    clear: both;
}
.col-1{
    display: table-cell;
    vertical-align: top;
    min-width: 285px;
    max-width: 285px;
    border-right: 1px solid #ccc;
}
.col-1 .btn{
    width: 94%;
    margin: 0;
}
.col-2{
    display: table-cell;
    padding-left: 20px;
    vertical-align: top;
}
.tags{
    font-size: 13px;
    white-space: pre-wrap;
    color: #4a4a4a;
    line-height: 1.4em;
}
a{
    color: #005dba;
    text-decoration: none;
    cursor: pointer;
}
a:hover{
    text-decoration: underline;
}
.item{
    padding-top: 10px;
    margin: 0  0 17px 10px;
}
.item a{
    padding: 4px 0 0 0;
    font-size: 16px;
    line-height: 1.3;
}
.group{
    position: relative;
    margin: 5px 5px 15px 0;
    padding: 5px 5px 5px 0;
}
.collapsed{
    padding-left: 35px;
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
    font-size: 16px;
}
.collapsed .arrow:before{
    font-family: 'icon-3m';
    speak: none;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e903";
    color: #005dba;
    display: inline-block;
    position: relative;
    top: 2px;
}
.collapsed .arrow{
    border: 0;
    padding: 0;
    text-align: center;
    z-index: 99;
    position: absolute;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    outline: none;
    transition: all 200ms ease-in;
    cursor: pointer;
}
.collapsed .arrow:focus{
    box-shadow: 0 0 0 2px #005dba;
}
.hidden.collapsed + .list-check{
    display: none;
}
.hidden.collapsed .arrow:before{
    transform: rotate(-90deg);
}
.list-check{
    display: block;
    list-style: none;
    padding-left: 0;
}
.checkbox{
    position: relative;
}
.checkbox label span{
    color: #687984;
}
.checkbox input[type="checkbox"] {
    display: none;
}
.checkbox input[type="checkbox"] + label {
    position: relative;
    display: inline-block;
    margin: 6px 20px 3px 36px;
    line-height: 26px;
    font-weight: normal;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 140ms ease-out;
}
.checkbox input[type="checkbox"] + label:before {
    content: " ";
    position: absolute;
    display: inline-block;
    top: 2px;
    left: -35px;
    cursor: pointer;
    vertical-align: middle;
    box-sizing: border-box;
    line-height: 1.34;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid #cad4d8;
    transition: all 280ms ease-out;
}
.checkbox input[type="checkbox"] + label:after {
    content: "\e902";
    left: -32px;
    top: 5px;
    color: #fff;
    font-family: 'icon-3m' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: absolute;
    display: inline-block;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 140ms ease-out;
    transition: all 140ms ease-out;
}
.checkbox input[type="checkbox"]:checked + label:before {
    color: white;
    background-color: #005dba;
    border: 2px solid #005dba;
}
.more-expanded{
    margin: 7px 8px 4px 10px;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    border-radius: 2px;
    padding-right: 2px;
    outline: none;
    font-weight: 500;
    color: #757575;
    vertical-align: middle;
    line-height: 1.34;
    border: 0;
}
.more-expanded:hover{
    text-decoration: none;
}
.more-expanded:focus{
    box-shadow: 0 0 0 2px white, 0 0 0 3px #35abed, 0 0 6px #35abed;
}
.more-expanded:hover span{
    text-decoration: underline;
}
.more-expanded:before{
    font-family: 'icon-3m' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-right: 5px;
    position: relative;
    top: 1px;
}
.more-expanded.more:before{
    content: "\e906";
}
.more-expanded.less:before{
    content: "\e907";
}
.footer{
    text-align: center;
    margin: 32px 0 24px;
    overflow: hidden;
}
.logo-footer{
    font-size: 10px;
    color: #98a0a6;
    display: inline-block;
    height: 15px;
    line-height: 10px;
    position: relative;
}
.logo-footer:before {
    content: " ";
    font-size: 0;
    display: block;
    width: 290px;
    height: 1px;
    background-color: #dce1e6;
    position: absolute;
    top: 18px;
    left: -290px;
}
.logo-footer:after {
    content: " ";
    font-size: 0;
    display: block;
    width: 290px;
    height: 1px;
    background-color: #dce1e6;
    position: absolute;
    top: 18px;
    right: -290px;
}
.copy{
    font-size: 10px;
    color: #4a4a4a;
    line-height: 20px;
    display: block;
    width: 100%;
    max-width: 560px;
    margin: 10px auto 0 auto;
    text-align: center;
}

/*Vacancy*/

h1{
    padding-top: 8px;
    font-size: 18px;
    color: #4a4a4a;
    word-break: break-word;
}
.body-vacancy,.side-vacancy{
    display: table-cell;
    vertical-align: top;
}
.body-vacancy{
    width: 65%;
    padding-right: 10px;
}
.side-vacancy{
    width: 35%;
    padding-left: 10px;
}
.side-vacancy .logo{
    position: static;
    box-shadow: none;
    margin: 0;
}
.list-tag{
    padding: 0;
    list-style: none;
    color: #4a4a4a;
    font-size: 12px;
}
.list-tag li{
    padding: 8px 0;
}
.list-tag i{
    color: #7598ad;
    font-size: 16px;
    margin-right: 4px;
}
.text-vacancy,p{
    color: #4a4a4a;
    line-height: 1.5;
}
.list-link{
    list-style: none;
    padding: 0;
}
.list-link li{
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.42;
}
.local{
    color: #4a4a4a;
}
.local i{
    color: #c5c5c5;
}
.controls,.text-vacancy{
    margin-top: 20px;
    padding-bottom: 10px;
}
.controls .btn:first-child{
    margin: 0;
}
.social{
    margin-top: 30px;
    text-align: center;
}
.follow{
    line-height: 1.34;
    display: inline-block;
    white-space: pre-wrap;
}
.follow-icon{
    margin-top: 8px;
}
.follow-icon > div:not(:first-child) {
    margin-left: 6px;
}
.social-link{
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 32px;
    background-color: #9da9af;
    transition: all 200ms ease-in;
    color: white;
    font-size: 16px;
    border-radius: 50%;
    outline: none;
}
.social-link:hover{
    background-color: #3f4e59;
    text-decoration: none;
}
.in:focus{
    background-color: #3a5a98;
}
.fb:focus{
    background-color: #027bb5;
}
.tw:focus{
    background-color: #29a7df;
}
.youtube:focus{
    background-color: #cd201f;
}
.modal-wrap{
    padding: 0;
}
.modal-wrap button{
    top: -5px;
    right: -45px;
    cursor: pointer;
    outline: none;
}
.modal-wrap button svg{
    fill: #fff;
    width: 40px;
    height: 40px;
}
.modal-wrap button:hover svg{
    fill: #ccc;
}
.back{
    background: transparent;
    border: 0;
    display: inline-block;
    font-size: 1.8rem;
    padding: 5px 10px 5px 0;
    vertical-align: middle;
    color: inherit;
    cursor: pointer;
    outline: none;
}
.back:hover{
    color: #000;
}

@media (max-width: 780px) {
    .col-1,.col-2,.body-vacancy,.side-vacancy{
        display: block;
        padding: 0;
        width: 100%;
        max-width: 100%;
        border: 0;
        box-sizing: border-box;
    }
    .body-vacancy,.side-vacancy{
        padding: 0;
    }
    .container{
        padding: 15px;
    }
    .logo{
        left: 15px;
        margin: 0;
    }
    .btn-group{
        right: 15px;
    }
    .btn{
        padding: 10px 20px 10px;
    }
    .modal-wrap{
        margin: auto 0;
        width: 100%;
    }
    .modal-wrap iframe{
        width: 100%;
    }
    .modal-wrap button{
        top: -42px;
        right: -8px;
    }
}
